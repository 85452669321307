var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('h1',{staticClass:"tw-p-0 tw-mb-2"},[_vm._v("Groups")]),_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',{staticClass:"tw-pt-0",attrs:{"md":"6"}},[_c('ax-text-field',{staticClass:"mb-0",attrs:{"id":"search-groups","autocomplete":"off","background-color":"component","prepend-inner-icon":_vm.mdiMagnify,"label":"Search Groups","clearable":"","hide-details":""},on:{"input":_vm.syncSearch},model:{value:(_vm.searchFilter),callback:function ($$v) {_vm.searchFilter=$$v},expression:"searchFilter"}})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('ax-toggle',{staticClass:"mb-0",attrs:{"label":("Show Unused Groups (" + _vm.unusedGroupCount + ")"),"hide-details":""},on:{"change":_vm.syncUnusedFilter},model:{value:(_vm.showOnlyUnusedFilter),callback:function ($$v) {_vm.showOnlyUnusedFilter=$$v},expression:"showOnlyUnusedFilter"}})],1)],1),_c('ax-table',{attrs:{"footer-props":_vm.groupsFooter,"headers":_vm.tableHeaders,"items":_vm.nextGroups,"options":_vm.tableState,"search":_vm.searchFilter,"item-key":"id","no-data-text":"No groups","disable-sort":""},on:{"update:options":function($event){_vm.tableState=$event}},scopedSlots:_vm._u([{key:"actions-left",fn:function(){return [_c('ax-button',{attrs:{"to":_vm.newGroupPath,"mode":"secondary","data-test-id":"open-create-group"}},[_c('v-icon',{staticClass:"tw-mr-2",attrs:{"small":""}},[_vm._v(_vm._s(_vm.mdiGroup))]),_vm._v(" Create Group ")],1)]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{style:({
          marginLeft:
            _vm.searchFilter || _vm.showOnlyUnusedFilter
              ? ''
              : ((0.25 * 5 * item.depth) + "rem"),
        })},[(_vm.searchFilter || _vm.showOnlyUnusedFilter)?_c('span',_vm._l((item.parent_labels),function(parent){return _c('span',{key:parent},[_vm._v(" "+_vm._s(parent)+" "),_c('v-icon',{staticClass:"tw-px-1",attrs:{"small":""}},[_vm._v(_vm._s(_vm.mdiChevronRight))])],1)}),0):_vm._e(),_c('router-link',{staticClass:"tw-inline-block tw-leading-tight",attrs:{"title":item.name || 'Default',"to":{
            name: 'groupEditor',
            query: {
              o: item.organization_id,
              frompage: '/manage/groups',
            },
            params: {
              groupId: item.id,
            },
          }}},[_c('v-icon',{staticClass:"tw-pr-2",attrs:{"color":item.ui_color,"x-small":""}},[_vm._v(_vm._s(_vm.mdiCircle))]),_c('span',[_vm._v(_vm._s(item.name || 'Default'))])],1)],1)]}},{key:"item.server_count",fn:function(ref){
          var item = ref.item;
return [_c('router-link',{staticClass:"enlarged-link",attrs:{"to":{
          path: "/devices",
          query: { o: item.organization_id, groupId: item.id },
        },"title":"View devices for this group"}},[_vm._v(" "+_vm._s(item.server_count)+" ")])]}},{key:"item.policy_count",fn:function(ref){
        var item = ref.item;
return [_c('policy-tooltip',{attrs:{"group":item,"policies":_vm.policies,"alignment":"right"}})]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('ax-table-row-actions',[_c('v-list-item',{on:{"click":function($event){return _vm.openModal(item, 'associatePolicies')}}},[_c('v-list-item-title',[_vm._v("Associate Policies")])],1),(item.id !== item.parent_server_group_id)?_c('v-list-item',{attrs:{"group":item},on:{"click":function($event){return _vm.removeGroup(item)}}},[_c('v-list-item-title',[_vm._v("Remove Group")])],1):_vm._e()],1)]}}])}),_c('ax-modal',{model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[(_vm.showModal && _vm.activeModal === 'associatePolicies')?_c('assign-policies-modal',_vm._g({attrs:{"group":_vm.selectedGroup,"policies":_vm.policiesSelect},on:{"close-modal":_vm.closeModal}},_vm.$listeners)):_vm._e(),(_vm.showModal && _vm.activeModal === 'deleteGroup')?_c('remove-group-modal',_vm._g({attrs:{"group":_vm.selectedGroup},on:{"delete-group":_vm.removeGroupFromDisplay,"close-modal":_vm.closeModal}},_vm.$listeners)):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }